import React from 'react';
import { Link } from 'gatsby';
// import PropTypes from 'prop-types';

import Layout from '../layouts/Layout';

const AboutScreen = () => {
  return (
    <Layout>
      <div>About</div>
      <Link to="/">Go Home</Link>
    </Layout>
  );
};

AboutScreen.propTypes = {};

AboutScreen.defaultProps = {};

export default AboutScreen;
