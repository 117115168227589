/* eslint-disable react/prop-types */
import React from 'react';

import AboutScreen from '../components/screens/AboutScreen';

const About = () => {
  return <AboutScreen />;
};

export default About;
